import React from "react"
import axios from 'axios'
import { Loading, Close, Edit, Down, Add, Note } from '../../images'
import './index.scss'
import { decodeEntities } from '../../utils/Functions'
import { getCurrentUser } from '../../utils/auth'
import GetInitials from '../GetInitials'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      settings: [],
      saveRequired: false,
      tempImageUpload: null,
      user:false,
      url: '',
      postId: 'new',
      uatTitle: '',
      imageIndex: null,
      imageSubIndex: null,
      activeGroup: null,
      uat: {
        uatClient: '',
        uatExternalUsers: [{
          userEmail: '',
          userName: '',
          userKey: '',
        }],
        reviews:[],
      },
      newReview : {
        pageUrl: '',
        issue: '',
        device: '',
        image: '',
        complete: false,
        user: '',
        comments: []
      },
      newComment: {
        comment:'',
        user: '',
        date: ''
      },
    }
  }

  loadData(){
    this.setState({ loading: true })
    console.log(this.state.loading)
    if (this.props.splat === 'new-uat') {
      this.setState({
        loading: false
      })
    } else {

      const user = getCurrentUser()
      //console.log('user:',user)
      const url = `${process.env.WP_ADDRESS}/wp-json/wp/v2/uat?slug=${this.props.splat}&_embed`
      //const settingsUrl = `${process.env.WP_ADDRESS}/wp-json/acf/v2/options`
      //console.log('settingsUrl:',settingsUrl)
      console.log('url:',url)
      axios.get(url)
      .then(res => {
        if (res.data[0]) {
          console.log('res.data[0]:',res.data[0])
          this.setState({
            user: user,
            postId: res.data[0].id,
            uatTitle: res.data[0].title.rendered,
            uat: res.data[0].uat,
            url: `${process.env.OKR_URL}/uat/${res.data[0].slug}/`,
            loading: false,
          })
        }else{
          this.setState({
            loading: false
          })
        }
      })
      .catch((err) => {
        console.log('error:', err)
        this.setState({
          loading: false,
          error: true
        })
      })
    }
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  revert(e) {
    this.loadData();
  }

  imageToPinPoint(e, index, subIndex) {
    this.setState({imageIndex: index, imageSubIndex: subIndex})
  }

  uploadImage(e, index) {
    e.preventDefault()
    const { uat, postId, tempImageUpload } = this.state
    const user = getCurrentUser()
    this.setState({loading:true})
    const uatImageArray = {
      postId: postId,
      user: user,
      tempImageUpload: tempImageUpload
    }
    console.log('uatImageArray:',uatImageArray)
    const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/secure/v2/uat?cookie=${user.cookie}`;
    console.log('wpPostMetaURL:',wpPostMetaURL)
    var params = {
      post: uatImageArray,
    }
    axios.post(wpPostMetaURL, params)
    .then(response => {
      console.log('response:',response.data)
      uat.reviews[index].image = response.data.image_url
      this.setState({
        postId: response.data.post_id,
        uat: uat,
        tempImageUpload: null,
        saveRequired:true,
        loading:false
      })
      //this.loadData(); //Make more dynamic
    })
    .catch(error => {
      this.setState({
        error:true,
        loading:false
      })
      console.error('submitForm Error', error)
    });
  }

  handleSubmit(e) {
     e.preventDefault()
     this.setState({loading:true})
     const user = getCurrentUser()
     //console.log('this.state.user:',user )
     //const user = this.state.user
     const uatArray = {
       postId: this.state.postId,
       uatTitle: this.state.uatTitle,
       uat: this.state.uat,
       user: user
     }

     const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/secure/v2/uat?cookie=${user.cookie}`;
     console.log('wpPostMetaURL:',wpPostMetaURL)

     var params = {
       post: uatArray
     }
     console.log('Post params:',params)
     axios.post(wpPostMetaURL, params)
     .then(response => {
       console.log('response:',response.data)
       this.setState({
         postId: response.data.post_id,
         saveRequired:false,
         loading:false
       })
       //this.loadData(); //Make more dynamic
     })
     .catch(error => {
        this.setState({
          error:true,
          loading:false
        })
        console.error('submitForm Error', error)
     });
  }

  //Form change
  handleChange(e, index = null, subIndex = null) {

    e.preventDefault()
    const { uat, user } = this.state
    const { name, value } = e.target
    if (subIndex !== null) {
      uat.reviews[parseInt(index)].comments[parseInt(subIndex)][name] = value
      uat.reviews[parseInt(index)].comments[parseInt(subIndex)].author = user.name
    } else if (index !== null) {
      uat.reviews[parseInt(index)][name] = value
    }else{
      uat[name] = value
    }
    this.setState(uat)
    this.setState({saveRequired:true})
  }

  addUser(event) {
    event.preventDefault()
    this.loadData(); //Testing
    const { uat } = this.state
    const newUserTemplate = {
      userEmail: '',
      userName: '',
      userKey: '',
    }
    uat.uatExternalUsers.push(newUserTemplate)
    return this.setState({ uat: uat, saveRequired:true });
  }

  removeUser(event, key) {
    event.preventDefault()
    const { uat } = this.state
    uat.uatExternalUsers.splice(key,1)
    return this.setState({ uat: uat, saveRequired:true });
  }

  addGroup(event) {
    event.preventDefault()

    const newGroupTemplate = {
      groupTitle: '',
      groupImage: '',
      comments: [{
        note: '',
        rating: '',
        imageRefX: '',
        imageRefY: ''
      }]
    }
    //Get the latest data to merge with
    const url = `${process.env.WP_ADDRESS}/wp-json/wp/v2/uat?slug=${this.props.splat}&_embed`
    axios.get(url)
    .then(res => {

      console.log('res.data[0]:',res.data[0])

      const passData = res.data[0].uat
      console.log('passData:',passData)
      if (passData && passData.reviews) {
        passData.reviews.push(newGroupTemplate)
        console.log('push')
      }else{
        passData.reviews = []
        passData.reviews.push(newGroupTemplate)
        console.log('pushsss')
      }
      this.setState({ uat: passData });

    })
    .catch((err) => {
      console.log('error:', err)
      this.setState({error: true})
    })
  }

  removeGroup(event, key) {
    event.preventDefault()
    const { uat } = this.state
    uat.reviews.splice(key,1)
    return this.setState({ uat: uat, saveRequired:true });
  }

  addComment(event, key){
    event.preventDefault()
    this.loadData(); //Testing
    const { uat } = this.state
    const newCommentTemplate = {
      note: '',
      rating: '',
    }
    uat.reviews[key].comments.push(newCommentTemplate)
    this.setState({ uat: uat, saveRequired:true });
  }

  removeComment(event, key, subKey ) {
    event.preventDefault()
    const { uat } = this.state
    uat.reviews[key].comments.splice(subKey,1)
    this.setState({ uat: uat, saveRequired:true });
  }

  queueImage(event, index) {
    event.preventDefault()
    let tempImageUpload = {}
     //If file
     if (event.target.type === 'file') {

       //let fileName = 'tempImageUpload'
       let file = event.target.files[0];
       tempImageUpload = { data: '', file: '', key: index }

       if (file) {
          const reader = new FileReader(file);
          reader.readAsDataURL(file);
          reader.onload = () => {
            // set image and base64'd image data in component state
            tempImageUpload.data = reader.result
            tempImageUpload.file = file.name
            tempImageUpload.key = index
          }
        }
     }
     return this.setState({tempImageUpload})
  }

  toggleActiveGroup(e, index) {
    const { activeGroup } = this.state
    activeGroup === index ? this.setState({ activeGroup: null }) : this.setState({ activeGroup: index })
  }

  render() {
    const { loading, error, user, uat, uatTitle, postId, tempImageUpload, saveRequired, imageIndex, imageSubIndex, activeGroup } = this.state
    const { reviews, uatClient, uatExternalUsers } = uat
    const { closeOkr, preview = false } = this.props
    console.log('uat:',uat)

    const ratings = ['Personal preference', 'Low', '', '','','Medium','','','','','High' ]

    if ( error ) {
      return (
        <div className="error-msg">
          <div>
            <h4>This UAT link has expired.</h4>
            <p>A new link can be provided by contacting the original sender.</p>
          </div>
        </div>
      )
    }

    return (
      <form
        method="post"
        onSubmit={event => this.handleSubmit(event)}
      >

      {saveRequired &&
        <div className="header-slicky">
          <span>The page needs to be saved.</span>
          <div className="page-actions">
            {loading && <div className="loading inline"><Loading/></div>}
            <button className="revert" onClick={e => this.revert(e)}>Revert</button>
            <button>Save</button>
          </div>
        </div>
      }

      <div className="uat">

        {preview &&
          <div className="address">
            <div className="inner">
              <span>
                Client Link: <a href={`${process.env.UAT_URL}/uat/${this.props.splat && this.props.splat}`} rel="noopener noreferrer" target="_blank">{process.env.UAT_URL}/uat/{this.props.splat && this.props.splat}</a> (This link will send you a slack notification when read.)
              </span>
              <button title="Close UAT" onClick={() => closeOkr()} className="close"><Close/></button>
              <a target="_blank" rel="noopener noreferrer" href={`${process.env.WP_ADDRESS}/wp-admin/post.php?auto_login=${user.cookie}&post=${postId}&action=edit&classic-editor`} title="Edit" className="edit"><Edit/></a>
            </div>
          </div>
        }


          <div className="uat-body">
            <div className="wrap">
              <div className="uat-head">

                <div className="title">
                  {preview ?
                    <div className={`field${uatTitle ? ' active' : ''}`}>
                      <label htmlFor="uatTitle">UAT Title</label>
                      <input
                        type="text"
                        name="uatTitle"
                        onChange={e => this.handleChange(e)}
                        placeholder="Name your UAT"
                        value={decodeEntities(uatTitle)}
                        required
                      />
                    </div>
                  :
                    <div>
                      <h1>{decodeEntities(uatTitle)}</h1>
                    </div>
                  }
                </div>

                <div className="client">
                  <div className={`field${uatClient ? ' active' : ''}`}>
                    <label htmlFor="uatClient">UAT Client</label>
                    <input
                      type="text"
                      name="uatClient"
                      onChange={e => this.handleChange(e)}
                      placeholder="UAT Client"
                      value={decodeEntities(uatClient)}
                      required
                    />
                  </div>
                </div>

              </div>

              <div className="users">

                {uatExternalUsers && uatExternalUsers.map((user,index) =>
                  <div className="user" key={index}>
                    <button className="remove" onClick={event => this.removeUser(event, index)} title="Remove User"><Close/></button>
                    <div className="fields">
                      <div className="name">

                        <div className={`field${uatExternalUsers[index].userName ? ' active' : ''}`}>
                          <label htmlFor="groupTitle">Name</label>
                          <input
                            type="name"
                            name="userName"
                            onChange={e => this.handleChange(e, index)}
                            placeholder="Name"
                            value={decodeEntities(uatExternalUsers[index].userName)}
                          />
                        </div>

                      </div>
                      <div className="email">

                        <div className={`field${uatExternalUsers[index].userEmail ? ' active' : ''}`}>
                          <label htmlFor="userEmail">Email</label>
                          <input
                            type="email"
                            name="userEmail"
                            onChange={e => this.handleChange(e, index)}
                            placeholder="Email"
                            value={decodeEntities(uatExternalUsers[index].userEmail)}
                          />
                        </div>

                      </div>
                      <div className="key">

                        <div className={`field${uatExternalUsers[index].userKey ? ' active' : ''}`}>
                          <label htmlFor="userKey">Key</label>
                          <input
                            type="text"
                            name="userKey"
                            onChange={e => this.handleChange(e, index)}
                            placeholder="Key"
                            value={decodeEntities(uatExternalUsers[index].userKey)}
                          />
                        </div>

                      </div>

                    </div>
                  </div>
                )}
                <button onClick={event => this.addUser(event)} className="add" title="Add User"><Add/> Add User</button>
              </div>

              {postId !== 'create new' &&
              <>
              <ul className="group-list">
              {reviews && reviews.map((item, index) => (
                <li className={`group-item${activeGroup === index ? ' active' : ''}`} key={index}>

                  <div className="issue-preview">

                    {preview && <button className="remove" onClick={event => this.removeGroup(event, index)} title="Remove Group"><Close/></button>}

                    <div className="title" onClick={e => this.toggleActiveGroup(e, index)}>
                      <p>{reviews[index].groupTitle ? reviews[index].groupTitle : 'Name your issue'}</p>
                      <div className="ref">
                        {reviews[index].pageUrl && reviews[index].pageUrl}
                        {reviews[index].device && ` - ${reviews[index].device}`}
                      </div>
                    </div>

                    <div className="image-preview">
                      <div className="scroll">
                        {reviews[index].image &&
                          <div className="image">
                            <img src={reviews[index].image} alt="Preview"/>
                          </div>
                        }
                      </div>
                    </div>

                    <div className="comments-count"><Note/> 2</div>

                    <div className="complete">
                      Done <input type="checkbox" />
                    </div>

                  </div>

                  <div className="full">

                    <div className="inner">

                      <div className="details">

                        <div className={`field${reviews[index].groupTitle ? ' active' : ''}`}>
                          <label htmlFor="groupTitle">Group Title</label>
                          <input
                            type="text"
                            name="groupTitle"
                            onChange={e => this.handleChange(e, index)}
                            placeholder="Group Title"
                            value={decodeEntities(reviews[index].groupTitle)}
                          />
                        </div>

                        <div className={`field${reviews[index].pageUrl ? ' active' : ''}`}>
                          <label htmlFor="device">Page Ref</label>
                          <input
                            type="text"
                            name="pageUrl"
                            onChange={e => this.handleChange(e, index)}
                            placeholder="Page Ref eg. URL"
                            value={decodeEntities(reviews[index].pageUrl)}
                          />
                        </div>

                        <div className={`field${reviews[index].device ? ' active' : ''}`}>
                          <label htmlFor="device">What devide is the issue on (all, windows...)</label>
                          <input
                            type="text"
                            name="device"
                            onChange={e => this.handleChange(e, index)}
                            placeholder="Device"
                            value={decodeEntities(reviews[index].device)}
                          />
                        </div>

                      </div>

                      <div className="large-image">

                        <div className="image">
                          <div className="image-preview">
                            <div className="scroll">
                              {reviews[index].image &&
                                <div className="image">
                                  <img src={reviews[index].image} alt="Preview"/>
                                </div>
                              }
                            </div>
                          </div>
                        </div>

                        <div className={`field${reviews[index].image ? ' active' : ''}`}>
                          <label htmlFor="note">Image</label>
                          <input
                            type="hidden"
                            name="image"
                            onChange={e => this.handleChange(e, index)}
                            placeholder="Image"
                            value={reviews[index].image}
                          />
                          <input
                            type="file"
                            name="groupImageUpload"
                            onChange={event => this.queueImage(event, index)}
                            placeholder="Image"
                          />
                          {tempImageUpload && tempImageUpload.key === index && <button onClick={event => this.uploadImage(event, index)} className="save-image">{loading && <div className="loading inline"><Loading/></div>}Save Image</button>}
                        </div>

                      </div>


                      <div className="comments">
                        <h4>Comments</h4>
                        <ul>
                        {item && item.comments && item.comments.map((comment, comIndex) => (
                          <li key={comIndex} onMouseOver={e => this.imageToPinPoint(e, index, comIndex)} className={index === imageIndex && imageSubIndex === comIndex ? ' active' : ''}>

                            {preview && <button onClick={event => this.removeComment(event, index, comIndex)} className="remove" title="Remove Comment"><Close/></button>}

                            {preview && comIndex > 0 && <button onClick={event => this.shiftComment(event, index, comIndex, comIndex - 1)} className="shift-up"><Down/></button>}

                            {preview && reviews[index].comments.length > comIndex +1 && <button onClick={event => this.shiftComment(event, index, comIndex, comIndex + 1)} className="shift-down"><Down/></button>}

                            {reviews[index].comments[comIndex].author && <div className="author"><GetInitials name={reviews[index].comments[comIndex].author}/></div>}

                            <div className="rating" title="Importance">{reviews[index].comments[comIndex].rating && <span className={`rating-${reviews[index].comments[comIndex].rating}`}>{ratings[reviews[index].comments[comIndex].rating]}</span>}</div>

                            {preview && user === 'you' ?
                              <div className={`field${reviews[index].comments[comIndex].note ? ' active' : ''}`}>
                                <label htmlFor="note">Note</label>
                                <textarea name="note" placeholder="Note" onChange={e => this.handleChange(e, index, comIndex)} value={decodeEntities(reviews[index].comments[comIndex].note)} />
                              </div>
                            :
                              reviews[index].comments[comIndex].note && <p dangerouslySetInnerHTML={{__html: decodeEntities(reviews[index].comments[comIndex].note).replace(/(\r\n|\n|\r)/gm, '<br/>')}}/>
                            }

                          </li>
                        ))}
                          <li>{preview && <button onClick={event => this.addComment(event, index)} className="add" title="Add Comment"><Add/>Add Comment</button>}</li>
                        </ul>
                      </div>

                      <div className="comments">
                        {reviews.comments && reviews.comments.map((comment, comIndex) =>
                          <div className="comment" key={comIndex}>

                            <div className={`field${reviews[index].comments[comIndex].comment ? ' active' : ''}`}>
                              <label htmlFor="note">Comment</label>
                              <textarea name="note" placeholder="Note" onChange={e => this.handleChange(e, index, comIndex)} value={decodeEntities(reviews[index].comments[comIndex].comment)} />
                            </div>

                          </div>
                        )}
                      </div>

                    </div>

                  </div>

                </li>
              ))}
                <li className="group-item">{preview && <button onClick={event => this.addGroup(event)} className="add" title="Add Group"><Add/> Add Issue</button>}</li>
              </ul>
              </>
              }
            </div>
          </div>
        </div>
      </form>
    )
  }
}


export default Index
